import { Box, BoxProps } from '@mui/material';

export default function AvatarBorder({
  size = 110,
  ...props
}: BoxProps & {
  size?: number;
}) {
  return (
    <Box
      sx={{
        ...props?.sx,
      }}
    >
      <svg
        width={size}
        height={size}
        viewBox="0 0 166 166"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_b_634_96453)">
          <mask id="path-1-inside-1_634_96453" fill="white">
            <path d="M39.9987 32.3494C52.6451 21.6162 68.8486 16.0007 85.4247 16.6065C102.001 17.2123 117.751 23.9956 129.58 35.6234C141.409 47.2512 148.462 62.8826 149.352 79.4459C150.243 96.0091 144.906 112.307 134.392 125.135C123.877 137.964 108.945 146.397 92.5291 148.776C76.1135 151.155 59.4015 147.309 45.6774 137.993C31.9533 128.678 22.2095 114.566 18.3607 98.4313C14.512 82.2968 16.8366 65.3063 24.8778 50.7986" />
          </mask>
          <path
            d="M40.6458 33.1119C53.1019 22.5401 69.0615 17.0091 85.3881 17.6058L85.4612 15.6072C68.6356 14.9922 52.1884 20.6922 39.3517 31.587L40.6458 33.1119ZM85.3881 17.6058C101.715 18.2025 117.228 24.8838 128.879 36.3366L130.281 34.9103C118.274 23.1075 102.287 16.2221 85.4612 15.6072L85.3881 17.6058ZM128.879 36.3366C140.53 47.7893 147.477 63.1855 148.354 79.4995L150.351 79.3922C149.447 62.5797 142.288 46.713 130.281 34.9103L128.879 36.3366ZM148.354 79.4995C149.231 95.8135 143.975 111.866 133.618 124.501L135.165 125.769C145.838 112.747 151.255 96.2047 150.351 79.3922L148.354 79.4995ZM133.618 124.501C123.262 137.137 108.554 145.443 92.3857 147.786L92.6726 149.766C109.335 147.351 124.492 138.791 135.165 125.769L133.618 124.501ZM92.3857 147.786C76.2171 150.13 59.7566 146.341 46.239 137.166L45.1158 138.821C59.0464 148.277 76.0099 152.181 92.6726 149.766L92.3857 147.786ZM46.239 137.166C32.7215 127.99 23.1243 114.091 19.3334 98.1993L17.388 98.6634C21.2947 115.041 31.1852 129.365 45.1158 138.821L46.239 137.166ZM19.3334 98.1993C15.5426 82.3076 17.8322 65.5728 25.7524 51.2834L24.0031 50.3138C15.8409 65.0398 13.4813 82.2861 17.388 98.6634L19.3334 98.1993Z"
            fill="#DCFFAF"
            fillOpacity="0.44"
            mask="url(#path-1-inside-1_634_96453)"
          />
        </g>
        <g filter="url(#filter1_ddf_634_96453)">
          <path
            d="M87.0419 16.6763C103.88 17.6922 119.701 25.0726 131.299 37.3213"
            stroke="#01FF75"
            strokeWidth="3"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g filter="url(#filter2_ddf_634_96453)">
          <path
            d="M22.7149 110.967C21.2458 107.808 20.0297 104.538 19.078 101.187C15.2734 87.788 15.7707 73.5328 20.5 60.4316"
            stroke="#01FF75"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <circle
          cx="83.0229"
          cy="83.0014"
          r="62.4549"
          transform="rotate(74.1449 83.0229 83.0014)"
          stroke="url(#paint0_radial_634_96453)"
          strokeWidth="0.5"
          strokeDasharray="2 2"
        />
        <circle
          cx="83.0229"
          cy="83.0014"
          r="62.4549"
          transform="rotate(74.1449 83.0229 83.0014)"
          stroke="url(#paint1_radial_634_96453)"
          strokeWidth="0.5"
          strokeDasharray="2 2"
        />
        <circle cx="83" cy="83.002" r="59" stroke="#38FF93" strokeWidth="2" />
        <path
          d="M117.812 136.189C116.663 135.039 117.425 132.627 116.838 131.214C116.25 129.802 114 128.564 114 127.002C114 125.439 116.225 124.252 116.838 122.789C117.45 121.327 116.663 118.964 117.812 117.814C118.962 116.664 121.375 117.427 122.788 116.839C124.2 116.252 125.438 114.002 127 114.002C128.562 114.002 129.75 116.227 131.212 116.839C132.675 117.452 135.038 116.664 136.188 117.814C137.337 118.964 136.575 121.377 137.163 122.789C137.75 124.202 140 125.439 140 127.002C140 128.564 137.775 129.752 137.163 131.214C136.55 132.677 137.337 135.039 136.188 136.189C135.038 137.339 132.625 136.577 131.212 137.164C129.8 137.752 128.562 140.002 127 140.002C125.438 140.002 124.25 137.777 122.788 137.164C121.325 136.552 118.962 137.339 117.812 136.189Z"
          fill="#090C11"
          stroke="#38FF93"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M132.5 124.002L125.163 131.002L121.5 127.502"
          stroke="#38FF93"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <filter
            id="filter0_b_634_96453"
            x="-23.4531"
            y="-23.4375"
            width="212.898"
            height="212.9"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
            <feComposite
              in2="SourceAlpha"
              operator="in"
              result="effect1_backgroundBlur_634_96453"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_backgroundBlur_634_96453"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_ddf_634_96453"
            x="74.5391"
            y="5.17578"
            width="69.2578"
            height="45.6445"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.291483 0 0 0 0 0.689567 0 0 0 0 0.570142 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_634_96453"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="5.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0524547 0 0 0 0 0.382031 0 0 0 0 0.263384 0 0 0 1 0"
            />
            <feBlend
              mode="hard-light"
              in2="effect1_dropShadow_634_96453"
              result="effect2_dropShadow_634_96453"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_634_96453"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="0.5"
              result="effect3_foregroundBlur_634_96453"
            />
          </filter>
          <filter
            id="filter2_ddf_634_96453"
            x="4.54688"
            y="49.4316"
            width="30.1719"
            height="74.5352"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset />
            <feGaussianBlur stdDeviation="5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.291483 0 0 0 0 0.689567 0 0 0 0 0.570142 0 0 0 1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_634_96453"
            />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="5.5" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.0524547 0 0 0 0 0.382031 0 0 0 0 0.263384 0 0 0 1 0"
            />
            <feBlend
              mode="hard-light"
              in2="effect1_dropShadow_634_96453"
              result="effect2_dropShadow_634_96453"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect2_dropShadow_634_96453"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="0.5"
              result="effect3_foregroundBlur_634_96453"
            />
          </filter>
          <radialGradient
            id="paint0_radial_634_96453"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(83.2177 87.4805) rotate(90) scale(102.308 1542.11)"
          >
            <stop stopColor="#4959EC" stopOpacity="0" />
            <stop offset="0.172108" stopColor="#545AE9" />
            <stop offset="0.495676" stopColor="#C161C9" />
            <stop offset="0.812641" stopColor="#4959EC" />
            <stop offset="1" stopColor="#4959EC" stopOpacity="0" />
          </radialGradient>
          <radialGradient
            id="paint1_radial_634_96453"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(83.2177 87.4805) rotate(90) scale(102.308 1542.11)"
          >
            <stop stopColor="#D7FFE5" />
            <stop offset="0.172108" stopColor="#2E7D32" />
            <stop offset="0.495676" stopColor="#DCFFAF" />
            <stop offset="0.812641" stopColor="white" />
            <stop offset="1" stopColor="#97F320" stopOpacity="0.1" />
          </radialGradient>
        </defs>
      </svg>
    </Box>
  );
}
