import { Box, Typography } from '@mui/material';
import { CrownIcon, TrophyIcon } from '../../../icons';

export function PositionBadge({ rank }: { rank: number }) {
  if (rank > 3) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: rank === 1 ? '#182920' : '#262829',
        color: rank === 1 ? '#38FF93' : '#F0F5FA',
        borderRadius: '100px',
        display: 'flex',
        gap: 1,
        justifyContent: 'center',
        alignItems: 'center',
        py: '3px',
        px: '6px',
        height: 'fit-content',
        width: 'fit-content',
      }}
    >
      {rank === 1 ? <CrownIcon /> : <TrophyIcon />}
      <Typography
        sx={{
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '12px',
        }}
      >
        {`${rank}${rank === 1 ? 'st' : rank === 2 ? 'nd' : 'rd'}`}
      </Typography>
    </Box>
  );
}
