import { Avatar, BadgeProps, Box } from '@mui/material';

import AvatarBorder from './AvatarBorder';

export default function AvatarWithBorder({
  src,
  name,
  size = 70,
  walletAddress,
  ...props
}: BadgeProps & {
  src?: string;
  name?: string;
  size?: number;
  walletAddress?: string;
  CustomBadge?: React.ReactNode;
}) {
  const outerSize = size * (100 / 70);

  return (
    <Box
      sx={{
        position: 'relative',
        height: outerSize,
        width: outerSize,
        ...props.sx,
      }}
    >
      <Avatar
        src={src || 'https://robohash.org/' + walletAddress}
        alt={name}
        sx={{
          width: size,
          height: size,
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          border: 'none',
          zIndex: 0,
        }}
      >
        {name?.slice(0, 2).toUpperCase()}
      </Avatar>

      <AvatarBorder
        size={outerSize}
        sx={{
          position: 'relative',
          zIndex: 1,
        }}
      />
    </Box>
  );
}
